body {
  /*height:100%;*/
  background-color: #EAEDED;
  /*background-image: url('./images/bgone.png');*/
  /*background-repeat: no-repeat;*/
  /*background-size: 100%;*/
  /*background-position: center bottom;*/
}

.custom-form {
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
}

.search-bar {
  background-color: #555555;
}

.custom-search-button {
  background-color: #002E5D;
  color: white;
  border: none;
  padding: 5px 13px;
  border-radius: 50px;
}

.custom-button-lg {
  font-size: 14px;
  background-color: #002E5D;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: .375rem;
}

.custom-button-lg:disabled {
  opacity: .4;
}

.input-error {
  border-color: red;
}

.form-label-required:after {
  content:"*";
  color:red;
}
